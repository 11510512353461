<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title class="row justify-center">Войти в систему</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent="login" id="login-form">
              <v-text-field label="Логин"
                            name="username"
                            prepend-icon="mdi-account"
                            type="text"
                            v-model="username"/>

              <v-text-field id="password"
                            label="Пароль"
                            name="password"
                            prepend-icon="mdi-lock"
                            type="password"
                            v-model="password"/>
            </v-form>
          </v-card-text>
          <v-card-actions class="row pb-3 no-gutters justify-end">
            <v-btn class="mx-2" rounded large type="submit" form="login-form" color="primary">Войти</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    login: function () {
      let username = this.username;
      let password = this.password;
      this.$store.dispatch("auth/login", {username, password})
          .then(() => this.$router.push("/"));
    }
  }
};
</script>